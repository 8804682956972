/**
 * 门店销售消耗业绩 
 */
 import * as API from '@/api/index'

 export default {
    // 门店销售消耗列表
    EntitySaleAndTreatList: params => {
        return API.POST('api/entitySaleAndTreatDetailStatement/list', params)
     },
    // 门店销售消耗导出
    EntitySaleAndTreatExcel: params => {
        return API.exportExcel('api/entitySaleAndTreatDetailStatement/excel', params)
     },

 }
<template>
  <div class="content_body ReportEntityProductAccountStatistics" style="padding:15px" v-loading="loading">
    <!-- 筛选 -->
    <div class="nav_header" style="padding:0 0 15px 0">
      <el-form :inline="true" size="small" :model="EntitySaleAndTreatDetail" @submit.native.prevent>
        <el-form-item v-if="storeEntityList.length>1" label="业绩门店">
          <el-select v-model="EntitySaleAndTreatDetail.EntityID" clearable filterable placeholder="请选择门店"
            :default-first-option="true" @change="handleEntitySaleAndTreatSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起止时间">
          <el-date-picker v-model="EntitySaleAndTreatDetail.QueryDate" unlink-panels type="daterange"
            range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="handleEntitySaleAndTreatSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleEntitySaleAndTreatSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="EntitySaleAndTreatExport" type="primary" size="small" :loading="downloadLoading"
            @click="downloadEntitySaleAndTreatExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-table size="small" show-summary :summary-method="getEntitySaleAndTreatSummaries" :data="result.list"
      v-loading="EntitySaleAndTreatLoading">
      <el-table-column prop="EntityName" label="门店"></el-table-column>
      <el-table-column prop="PassengerFlow" align="right" label="门店客流"></el-table-column>
      <el-table-column prop="CurrentSavingCardLargessAmount" align="right" label="本期赠送储值卡金额">

        <template slot-scope="scope">
          {{scope.row.CurrentSavingCardLargessAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="CurrentProductLargessQuantity" align="right" label="本期赠送产品数量"></el-table-column>
      <el-table-column prop="CurrentProductLargessAmount" align="right" label="本期赠送产品市场价值">
        <template slot-scope="scope">
          {{scope.row.CurrentProductLargessAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="CurrentCardLargessAmount" align="right" label="本期赠送卡项总价值">
        <template slot-scope="scope">
          {{scope.row.CurrentCardLargessAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="PayPerformance" align="right" label="成交实收总金额">
        <template slot-scope="scope">
          {{scope.row.PayPerformance | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="SavingCardPerformance" align="right" label="储值卡抵扣金额">
        <template slot-scope="scope">
          {{scope.row.SavingCardPerformance | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="SavingCardLargessPerformance" align="right" label="赠卡抵扣金额">
        <template slot-scope="scope">
          {{scope.row.SavingCardLargessPerformance | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="ProductPayPerformance" align="right" label="成交产品金额">
        <template slot-scope="scope">
          {{scope.row.ProductPayPerformance | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="SaleBillQuantity" align="right" label="成交订单数"></el-table-column>
      <el-table-column prop="SaleBillDealHead" align="right" label="成交人头"></el-table-column>
      <el-table-column prop="SalePrice" align="right" label="人均成交单价">
        <template slot-scope="scope">
          {{scope.row.SalePrice | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="ConsumeProjectPerformance" align="right" label="消耗项目实收业绩">
        <template slot-scope="scope">
          {{scope.row.ConsumeProjectPerformance | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="ConsumeProjectQuantity" align="right" label="消耗项目数"></el-table-column>
      <el-table-column prop="ConsumeProjectCount" align="right" label="消耗项目人次"></el-table-column>
      <el-table-column prop="ConsumeProjectHead" align="right" label="消耗项目人头"></el-table-column>
      <el-table-column prop="ConsumePrice" align="right" label="人均消耗单价">
        <template slot-scope="scope">
          {{scope.row.ConsumePrice | NumFormat}}
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="EntitySaleAndTreatPaginations.total > 0"
        @current-change="handleEntitySaleAndTreatPageChange" :current-page.sync="EntitySaleAndTreatPaginations.page"
        :page-size="EntitySaleAndTreatPaginations.page_size" :layout="EntitySaleAndTreatPaginations.layout"
        :total="EntitySaleAndTreatPaginations.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import API from "@/api/Report/Entity/saleAndTreatCommissionStatistics";
import EntityAPI from "@/api/Report/Common/entity";
import permission from "@/components/js/permission.js";
import dateTime from '@/components/js/date'
export default {
  name: "ReportEntitySaleAndTreatCommissionStatistics",

  components: {},

  directives: {},

  data() {
    return {
      loading: false,
      storeEntityList: [], //业绩门店
      // 搜索条件
      EntitySaleAndTreatDetail: {
        EntityID: null,
        QueryDate: [],
      },
      // 搜索结果
      result: {
        totalForm: {},
        list: [],
      },
      EntitySaleAndTreatExport: false, //导出权限
      downloadLoading: false,
      EntitySaleAndTreatLoading: false,
      EntitySaleAndTreatPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },
  created() {
    this.EntitySaleAndTreatDetail.QueryDate = [
      dateTime.formatDate.format(new Date(), "YYYY-MM-DD"),
      dateTime.formatDate.format(new Date(), "YYYY-MM-DD"),
    ];
  },
  mounted() {
    const that = this;
    //   导出权限
    that.EntitySaleAndTreatExport = permission.permission(
      that.$route.meta.Permission,
      "Report-Entity-SaleAndTreatCommissionStatistics-Export"
    );
    //获得业绩门店
    that.getstoreEntityList();
    // 搜索
    that.handleEntitySaleAndTreatSearch();
  },

  methods: {
    //获得所属门店
    getstoreEntityList() {
      var that = this;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 搜索
    handleEntitySaleAndTreatSearch() {
      var that = this;
      that.EntitySaleAndTreatPaginations.page = 1;
      that.getEntitySaleAndTreat();
    },
    // 获取门店销售消耗业绩列表
    getEntitySaleAndTreat() {
      const that = this;
      const param = that.EntitySaleAndTreatDetail;

      if (param.QueryDate != null) {
        that.EntitySaleAndTreatLoading = true;
        const params = {
          EntityID: param.EntityID,
          StartTime: param.QueryDate[0],
          EndTime: param.QueryDate[1],
          PageNum: that.EntitySaleAndTreatPaginations.page,
        };
        API.EntitySaleAndTreatList(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.result.totalForm =
                res.Data.entitySaleAndTreatDetailSumStatementForm;
              that.result.list = res.Data.detail.List;
              that.EntitySaleAndTreatPaginations.total = res.Data.detail.Total;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.EntitySaleAndTreatLoading = false;
          });
      }
    },
    // 导出
    downloadEntitySaleAndTreatExcel() {
      var that = this;
      const param = that.EntitySaleAndTreatDetail;
      if (param.QueryDate != null) {
        const params = {
          EntityID: param.EntityID,
          StartTime: param.QueryDate[0],
          EndTime: param.QueryDate[1],
        };
        that.downloadLoading = true;
        API.EntitySaleAndTreatExcel(params)
          .then((res) => {
            this.$message.success({
              message: "正在导出",
              duration: "4000",
            });
            const link = document.createElement("a");
            let blob = new Blob([res], { type: "application/octet-stream" });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "门店销售消耗业绩统计.xlsx"; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .finally(function () {
            that.downloadLoading = false;
          });
      }
    },
    // 合计
    getEntitySaleAndTreatSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "PassengerFlow":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.PassengerFlow}
              </span>
            );
            break;
          case "CurrentSavingCardLargessAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.CurrentSavingCardLargessAmount
                )}
              </span>
            );
            break;
          case "CurrentProductLargessQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.CurrentProductLargessQuantity}
              </span>
            );
            break;
          case "CurrentProductLargessAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.CurrentProductLargessAmount
                )}
              </span>
            );
            break;
          case "CurrentCardLargessAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.CurrentCardLargessAmount
                )}
              </span>
            );
            break;
          case "PayPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.PayPerformance)}
              </span>
            );
            break;
          case "SavingCardPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.SavingCardPerformance)}
              </span>
            );
            break;
          case "SavingCardLargessPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.SavingCardLargessPerformance
                )}
              </span>
            );
            break;
          case "ProductPayPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.ProductPayPerformance)}
              </span>
            );
            break;
          case "SaleBillQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.SaleBillQuantity}
              </span>
            );
            break;
          case "SaleBillDealHead":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.SaleBillDealHead}
              </span>
            );
            break;
          case "SalePrice":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.SalePrice)}
              </span>
            );
            break;
          case "ConsumeProjectPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.ConsumeProjectPerformance
                )}
              </span>
            );
            break;
          case "ConsumeProjectQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.ConsumeProjectQuantity}
              </span>
            );
            break;
          case "ConsumeProjectCount":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.ConsumeProjectCount}
              </span>
            );
            break;
          case "ConsumeProjectHead":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.ConsumeProjectHead}
              </span>
            );
            break;
          case "ConsumePrice":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.ConsumePrice)}
              </span>
            );
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    // 分页
    handleEntitySaleAndTreatPageChange() {
      this.getEntitySaleAndTreat();
    },
  },
};
</script>

<style lang="scss">
.ReportEntityProductAccountStatistics {
}
</style>